import React from "react"
import styled from '@emotion/styled';
import About from "../components/about"
import translate from "../utils/translation";
import { MainTitle } from "../components/common/title";

const SubTitle = styled.h2`
  margin: 0 0 20px;
  padding: 0;
  font-size: 20px;
  line-height: 1.1;
  color: #2f2f2f;
`;

const Content = styled.p`
  margin: 0;
  padding: 0;
  font-size: 16px;
  line-height: 1.88;
`;

const Wrapper = styled.section`
  padding-top: 170px;
`;

const Container = styled.div`
  max-width: 1000px;
  margin: 0 auto;

  @media (max-width: 990px) {
    padding: 0 24px;
  }
`;

const ContentContainer = styled.div`
  width: 50%;
  margin-bottom: 100px;

  @media (max-width: 760px) {
    width: 100%;
    margin-bottom: 60px;
  }

  &:nth-child(even) {
    margin-left: auto;
  }
`;

export default () =>
<About aboutIndex={0}>
  <Wrapper>
    <Container>
      <ContentContainer>
        <MainTitle>{translate("headers.whatIsNBW")}</MainTitle>
        <Content>{translate('about.whatIs.nBWIs')}</Content>
      </ContentContainer>
      <ContentContainer>
        <SubTitle>{translate('about.whatIs.morningPagesTitle')}</SubTitle>
        <Content>{translate('about.whatIs.morningPagesDesc')}</Content>
      </ContentContainer>
      <ContentContainer>
        <SubTitle>{translate('about.whatIs.howItWorksTitle')}</SubTitle>
        <Content>{translate('about.whatIs.howItWorksDesc')}</Content>
      </ContentContainer>
    </Container>
  </Wrapper>
</About>;